<script>
import AxiosSelect from '@base-inputs/AxiosSelect';
import Api from "@helpers/api";
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;

export default {
  name: 'AuctionTypesSelect',
  extends: AxiosSelect,
  props: {
    clearable: {
      default: () => false
    },
    value: {},
    axiosUrl: {
      default: () => Resource('AuctionTypes')
    }
  }
};
</script>
