
import {camelCase} from "lodash";

const files = require.context('./requests', true, /\.js$/)
let methods = {};
for (const file of files.keys()) {
  let method = files(file)
  method = method.default || method;

  let name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')

  name = camelCase(name)
  methods[name] = method
}
const Api = {
  methods,
}
export default Api
