export default {
    name_ar: 'الاسم العربي',
    name_en: 'الاسم الانجليزي',
    cr_number: 'رقم السجل',
    phone_number: 'رقم الهاتف',
    show_owner: 'عرض المالك في التطبيق',
    disable_login: 'تعطيل الدخول',
    auction_type_id: 'نوع المزاد',
    contract_type_id: 'نوع التعاقد',
    erp_code: 'رقم حساب المالية',
};
