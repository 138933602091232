export default {
      name_ar: 'الاسم العربي',
    name_en: 'الاسم الانجليزي',
    client_id: 'العميل',
    start_date: 'Auction Start Date',
    start_time: 'Auction Start Time',
    end_date: 'Auction End Date',
    end_time: 'Auction End Time',

};
