export default {
    plate: 'plate',
    chassie: 'chassie',
    request: 'request No',
    type: 'Vehicle type',
    model: 'Model',
    warehouse_id: 'Warehouse',
    warehouse: 'Warehouse',
    storage_location_id: 'Storage location',
    owner_name1: 'Contact name',
    owner_name: 'Contact name',
    real_owner_name: 'Owner name',
    insurance_name: 'Insurance name',
    left_address: 'Pull Address',
    km: 'ODO Meter',
    city_id: 'City',
    color: 'Color',
    car_category_id: 'Car Category',
    client_id: 'Client',
    auction_id: 'Auction',
    auction_no: 'No In Auction',
    damage_type_id: 'Damage',
    store_damage_type_id: 'Store Damage',
    trans_request: 'Transfer request No',
    trans_request_date: 'Transfer request date',
    check_in_date: 'Check in date',
    transporter_id: 'Transporter',
    transporter: 'Transporter',
    transporter_driver_id: 'Transporter Driver',
    upload_leftletter: 'Upload Pull letter',
    upload_authorizationletter: 'Upload Authorization letter',
    upload_carid: 'Upload vehicle registration',
    upload_carphoto: 'Upload carphoto',
    upload_exitdocument: 'Upload vehicle exit document',
    upload_all: 'Upload Other attachments',
    carid: 'Vehicle registration',
    carphoto: 'Vehicle photo',
    storephoto: 'Store photo',
    exitdocument: 'Vehicle exit document',
    car_exit: 'Vehicle Exit',
    leftletter_date: 'Pull letter receive date',
    inventory: 'Inventory',
    transportation_fees: 'Transportation fees',
    excelimport: 'Excel file',
    exceltemplate: 'Excel Template file',
    leftletter: 'Pull letter',
    inspection_report: 'Vehicle Inspection Report',
    authorizationletter: 'ِuthorization letter',
    check_out_date: 'Check out date',
    attachment_type: 'Attachment type',
    car_details: 'Vehicle details',
    service_request_details: 'Service Request details',
    receiver_details: 'contact details',
    number: ' In auction No ',
    noperm: 'you dont have permission to access!!',
    request_new: 'Pull request',
    requests_new: 'Pul requests',
    car_checkinout: 'Vehicle In/Out Log',
    check_date: 'Log date',
    check_type: 'Log type',
    car_upadates: 'Vehicle Updates',
    update_date: 'Update date',
    update_message: 'Update',
    update_status: 'Update status',
    upload_leftletter_date: 'Pull letter receive date',
    enter_car_in: 'Enter Vehicle to warehouse',
    exit_car: 'Out for sale',
    exit_car1: 'Out for sale',
    exit_car2: 'Out per request',
    exit_car3: 'Out for evaluation',
    otherattach: 'Other attachments',
    attachments: 'Vehicle attachments',
    storeattachments: 'Store',
    store_brief: 'Brief',
    show_in_store: 'Show in Store',
    costs: 'Vehicle costs',
    expens: 'Vehicle expenses',
    back_to_car: 'Back to Vehicle details',
    add_note: 'Add update',
    note_type: 'update type',
    note_notify: 'Notify by email',
    assign_driver: 'Assign driver',
    refuse_trans: 'Update with Unable transfer',
    refuse_reason: 'Unable transfer reason',
    re_trans: 'Update request Re-Transfer',
    inventory_note: 'Update Vehicle Inventory',
    inventory_enter: 'Vehicle Inventory',
    inventory_edit: 'Edit Inventory',
    inventory_good: 'Not miss in Inventory',
    normal_note: 'General update',
    enter_this_car: 'Enter Vehicle',
    printPDF: 'Print/PDF',
    notetranstitle: 'Vehicle waiting tranportation',
    notetransmessage1: 'Vehicle No ',
    notetransmessage2: ' waiting tranportation',
    noteaddtitle: 'Add Vehicle',
    noteaddmessage1: 'has been added Vehicle No  ',
    noteaddmessage2: 'by Client ',
    noteaddclienttitle: 'Important Warning!!',
    noteaddclientmessage1: 'Vehicle with plate will not transfer ',
    noteaddclientmessage2: 'transporter unless you upload vehicle pull request',
    noteaddnotemessage: 'update added for vehicle No ',
    notenoterefusemessage: 'Unable to transfer vehicle No ',
    noteretransmessage: 'Re transfer request added for vehicle No ',
    noteentermessage1: 'Vehicle No ',
    noteentermessage2: 'entered to warehouse ',
    noteaddnotetitle: ' Vehicle general update',
    noterefusetitle: 'Unable to transfer the Vehicle',
    noteretranstitle: 'Requeste Re-Transfer the vehicle',
    noteentertitle: 'Enter the vehicle to warehose',
    user: 'User',
    exit_car2_note: 'Out by request notes',
    car_id: 'Vehicle ID',
    carrequeststutas0:  "In Progress",
    carrequeststutas1:  "Vehicle Founded",
    carrequeststutas2:  "Vehicle Sold",
    carrequeststutas3:  "Vehicle Not Founded",
    carstutas0: "New Request",
    carstutas1: "With Transporter",
    carstutas2: "Unable Transfer Vehicle",
    carstutas3: "Transportation in Progress",
    carstutas4: "In Warehouse",
    carstutas5: "Out for Evaluate",
    carstutas6: "In Warehouse/In Acution: ",
    carstutas7: "Sold",
    carstutas8: "Out by Request",
    carstutas9:  "Pending Payment",
    carstutas10: "Sold/Waiting Ownership Transfer/Drop",
    carstutas11: "Unable Ownership Transfer/Drop",
    carstutas12: "Request Cancel",
    carstutas13: "Cancel Request Cancel",
};
