import routes from '@routes/config';

export default [{
    path: routes.auction.client,
    name: routes.auction.client,
    component: () => import(/* webpackChunkName: "AuctionClient" */  '@components/Auction/Client')
},
{
    path: routes.auction.index,
    name: routes.auction.index,
    component: () => import(/* webpackChunkName: "Auction" */  '@components/Auction/Index')
}];
