export default {
      name_ar: 'الاسم العربي',
    name_en: 'الاسم الانجليزي',
    client_id: 'العميل',
    start_date: 'تاريخ بدء المزاد',
    start_time: 'ساعة بدء المزاد',
    end_date: 'تاريخ نهاية المزاد',
    end_time: 'ساعة نهاية المزاد',

};
