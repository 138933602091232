/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    car_manufacturer_id: 'الشركة المصنعة',
    car_color_id: 'اللون',
  immediate_transfer: 'نقل ملكية فوري',
    car_model_id: 'طراز المركبة',
  attachments: 'المرفقات',
};
