export default {
    menu_url: 'رابط القائمة',
    menu_url_hint: 'حقل رابط القائمة مطلوب',
    menu_type: 'menu_type',
    sort_order: 'ترتيب العرض',
    status: 'الحالة',
    model_name: 'تتبع لموديول',
    model_name_hint: 'حقل تتبع لموديول مطلوب',
    perm: 'صلاحيات القائمة',
    perm_hint: 'حقل صلاحيات القائمة مطلوب',
    icon: 'ايقونة القائمة',
    icon_hint: 'حقل ايقونة القائمة مطلوب',
    system_type: 'تتبع لنظام',
    system_type_hint: 'حقل تتبع لنظام مطلوب',
    menu_type1: 'قائمة رئيسية',
    menu_type2: 'قائمة فرعية',
    service_request_type0: 'نقل ملكية',
    service_request_type1: 'إسقاط ملكية',
};
