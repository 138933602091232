<template>
  <v-dialog persistent class="ma-0 pa-0 mt-1" v-model="show" max-width="1024">
    <v-card flat loader-height="30" tile>
      <v-toolbar class="justify-content-center" color="secondary" max-height="30" height="30">
        <v-toolbar-title v-text="GetTitle()"/>
      </v-toolbar>
      <v-card-text>
        <div class="pt-2">
            <app-row>
              <app-textarea-input v-model="selectedItem.note_text" :label="parseAttribute('notes')" name="note_text" sm="12" md="12" rules="required"/>
            </app-row>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-submit @click="submitRequestNoteForm" :loading="formDialogLoading">{{ $t('save') }}</app-submit>
        <app-btn :loading="formDialogLoading" color="error" min-width="120" tooltip="close_window" @click="showData = false"            >
          {{ $t('close') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 0 10px 0 0 !important;
}
.v-text-field input{
  padding: 0;
}
</style>
<style
    lang="sass"
    scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1
</style>
<style>
.v-expansion-panel-header {
  padding: 8px 5px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  justify-content: center !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  height: auto !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height: auto !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
  border-bottom: thin solid #0000008a;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #c5c5c5;
}
.v-tab{
  padding: 0 10px !important;
  min-width: 35px !important;
}
.v-btn--fab.v-size--small {
  height: 35px !important;
  width: 35px !important;
}
.mhf-btn{
  background: -webkit-linear-gradient(top, rgb(28, 109, 249) 0%,rgb(22, 29, 165) 100%) !important;
}
</style>
<script>

import MetaInfoMixin from "@mixins/MetaInfoMixin";
import DatatableMixin from "@mixins/DatatableMixin";

export default {
  name: 'ChamgeRequestStatusDialog',
  helperApiName: 'requestServiceNote',
  mixins: [MetaInfoMixin, DatatableMixin],
  data() {
    const apiMethods = this.$api.methods[this.$options.helperApiName];
    return {
      apiMethods,
      formDialogLoading: false,
      formErrors: {},
      selectedItem: {},
      dialog: false,
    }
  },
  props: {
    show: {
      type: Boolean,
      required: !0
    },
    service_request_id: {
      type: Number,
      required: !0
    },
    service_request_status: {
      type: Number,
      required: !0
    },
  },
  computed: {
    showData: {
      set(n) {
        this.$emit('update:show', n);
      },
      get() {
        return this.show
      },
    },
  },
  methods: {
    GetTitle(){
      if(this.service_request_status===0){
        return this.parseAttribute('refresh')+this.parseAttribute('refresh_costs');
      }
      if(this.service_request_status===2){
        return this.parseAttribute('refuse')+this.parseAttribute('refuse_costs');
      }
      if(this.service_request_status===3){
        return this.parseAttribute('approve')+this.parseAttribute('approve_costs');
      }
      if(this.service_request_status===4){
        return this.parseAttribute('cancel')+this.parseAttribute('cancel_costs');
      }
      if(this.service_request_status===5){
        return this.parseAttribute('completed')+this.parseAttribute('completed_request');
      }
      return this.parseAttribute('change')+' '+this.parseAttribute('service_request_status')
    },
    submitRequestNoteForm() {
      if (this.formDialogLoading) return;
      this.formDialogLoading = !0;
      this.selectedItem.service_request_id = this.service_request_id;
      this.selectedItem.new_status = this.service_request_status;
      this.$axios.post(`Panel/ChangeRequestStatus`, this.selectedItem)
        .then(data => {
          if (data.data && data.data.success) {
          this.alertSuccess(data.message);
          this.selectedItem={};
          this.refreshDatatable();
          this.showData = false;
        }
      })
        .catch(({response}) => {
          this.resetForm();
          const {data} = response || {};
          data && data.message && this.alertError(data.message);
          this.setErrors(data.errors || {});
        })
        .finally(() => this.formDialogLoading = !1);
    },
  },
  mounted() {

  }
}
</script>

