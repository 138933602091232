export default {
  plate: 'Plate',
  chassie: 'Chassie',
  type: 'Type',
  request_type: 'Request Type',
  model: 'Model',
  owner_name: 'Owner Name',
  client_id: 'Client',
  status: 'Request Status',
  request_uploadid: 'Upload Vehicle Registration',
};
