<template>
  <component
      :is="getComponentName"
      v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'ColSpan',
  props: {
    lazyModel: {
      type: Boolean,
      default: () => false
    },
    withCol: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      // lazy: this.lazyModel
    };
  },
  computed: {
    getComponentName() {
      return this.withCol ? 'app-col' : 'span';
    },
    lazy: {
      get() {
        return this.lazyModel;
      },
      set(v) {
        this.$emit('update:lazyModel', v);
      }
    }
  },
  mounted() {
    // this.lazy = this.lazyModel;
  }
};
</script>
