

<template>
  <app-tooltip v-bind="$attrs" v-on="$listeners" :disabled="tooltipDisabled" :text="getTooltip">
    <v-btn v-bind="$attrs" v-on="$listeners" :class="getClasses"
        :min-height="minHeight"
        :min-width="minWidth" fab small>
      <template v-if="create"><v-icon :style="cuStyles" color="success">mdi-plus</v-icon></template>
      <template v-if="update"><v-icon :style="cuStyles" color="success">mdi-pencil</v-icon></template>
      <template v-if="show"><v-icon :style="cuStyles" color="primary">mdi-eye</v-icon></template>
      <template v-if="convert"><v-icon :style="cuStyles" color="primary">mdi-account-convert</v-icon></template>
      <template v-if="destroy"><v-icon :style="cuStyles" color="error">mdi-delete</v-icon></template>
      <template v-if="refresh"><v-icon :style="cuStyles" color="success">mdi-refresh</v-icon></template>
      <template v-if="refuse"><v-icon :style="cuStyles" color="error">mdi-account-cancel</v-icon></template>
      <template v-if="cancel"><v-icon :style="cuStyles" color="error">mdi-cancel</v-icon></template>
      <template v-if="link_to_bill"><v-icon :style="cuStyles" color="primary">mdi-palette</v-icon></template>
      <template v-if="upload"><v-icon :style="cuStyles" color="primary">insert_drive_file</v-icon></template>
      <template v-if="auction"><v-icon :style="cuStyles" color="primary">mdi-arrow-up-bold-circle-outline</v-icon></template>
      <template v-if="driver"><v-icon :style="cuStyles" color="primary">mdi-arrow-up-bold-circle-outline</v-icon></template>
      <template v-if="enter"><v-icon :style="cuStyles" color="primary">mdi-arrow-down-bold-circle-outline</v-icon></template>
      <template v-if="approve"><v-icon :style="cuStyles" color="primary">mdi-location-exit</v-icon></template>
      <template v-if="exit"><v-icon :style="cuStyles" color="primary">mdi-location-exit</v-icon></template>
      <template v-if="exit2"><v-icon :style="cuStyles" color="success">mdi-location-exit</v-icon></template>
      <template v-if="completed"><v-icon :style="cuStyles" color="success">mdi-location-exit</v-icon></template>
      <template v-if="exit3"><v-icon :style="cuStyles" color="error">mdi-location-exit</v-icon></template>
      <template v-if="inventory"><v-icon :style="cuStyles" color="success">mdi-package-variant-closed</v-icon></template>
      <template v-if="inventory2"><v-icon :style="cuStyles" color="error">mdi-package-variant-closed</v-icon></template>
      <template v-if="move"><v-icon :style="cuStyles" color="primary">mdi-bank-transfer</v-icon></template>
      <template v-if="unlink"><v-icon :style="cuStyles" color="error">mdi-comment-remove</v-icon></template>
      <template v-if="closesync"><v-icon :style="cuStyles" color="success">mdi-comment-flash</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    minHeight: {
      type: [String, Number],
      default: () => undefined
    },
    link_to_bill: {
      type: Boolean,
      default: () => false
    },
    unlink: {
      type: Boolean,
      default: () => false
    },
    closesync: {
      type: Boolean,
      default: () => false
    },
    cuStyles: {
      type: [String],
      default: () => undefined
    },
    create: {
    type: Boolean,
    default: () => false
  },
    createaddtext: {
    type: String,
    default: () => ''
  },
    update: {
      type: Boolean,
      default: () => false
    },
    updateaddtext: {
      type: String,
      default: () => ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    showaddtext: {
      type: String,
      default: () => ''
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    destroyaddtext: {
      type: String,
      default: () => ''
    },
    convert: {
      type: Boolean,
      default: () => false
    },
    convertaddtext: {
      type: String,
      default: () => ''
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    uploadaddtext: {
      type: String,
      default: () => ''
    },
    driver: {
      type: Boolean,
      default: () => false
    },
    driveraddtext: {
      type: String,
      default: () => ''
    },
    auction: {
      type: Boolean,
      default: () => false
    },
    auctionaddtext: {
      type: String,
      default: () => ''
    },
    enter: {
      type: Boolean,
      default: () => false
    },
    enteraddtext: {
      type: String,
      default: () => ''
    },
    approve: {
      type: Boolean,
      default: () => false
    },
    approveaddtext: {
      type: String,
      default: () => ''
    },
    completed: {
      type: Boolean,
      default: () => false
    },
    completedaddtext: {
      type: String,
      default: () => ''
    },
    cancel: {
      type: Boolean,
      default: () => false
    },
    canceldaddtext: {
      type: String,
      default: () => ''
    },
    refuse: {
      type: Boolean,
      default: () => false
    },
    refusedaddtext: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Boolean,
      default: () => false
    },
    refreshaddtext: {
      type: String,
      default: () => ''
    },
    exit: {
      type: Boolean,
      default: () => false
    },
    exitaddtext: {
      type: String,
      default: () => ''
    },
    exit2: {
      type: Boolean,
      default: () => false
    },
    exit2addtext: {
      type: String,
      default: () => ''
    },
    exit3: {
      type: Boolean,
      default: () => false
    },
    exit3addtext: {
      type: String,
      default: () => ''
    },
    move: {
      type: Boolean,
      default: () => false
    },
    moveaddtext: {
      type: String,
      default: () => ''
    },
    inventory: {
      type: Boolean,
      default: () => false
    },
    inventoryaddtext: {
      type: String,
      default: () => ''
    },
    inventory2: {
      type: Boolean,
      default: () => false
    },
    inventory2addtext: {
      type: String,
      default: () => ''
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2',
      'md-3',
      'sm-6',
      'xs-6',
      'mx-1'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.create) return this.$t('create')+this.createaddtext;
      if(this.update) return this.$t('update')+this.updateaddtext;
      if(this.show) return this.$t('show')+this.showaddtext;
      if(this.convert) return this.$t('convert')+this.convertaddtext;
      if(this.driver) return this.parseAttribute('assign_driver')+this.driveraddtext;
      if(this.auction) return this.parseAttribute('assign_auction')+this.auctionaddtext;
      if(this.enter) return this.parseAttribute('enter_this_car')+this.enteraddtext;
      if(this.approve) return this.parseAttribute('approve')+this.approveaddtext;
      if(this.exit) return this.parseAttribute('exit_car')+this.exitaddtext;
      if(this.exit2) return this.parseAttribute('exit_car2')+this.exit2addtext;
      if(this.completed) return this.parseAttribute('completed')+this.completedaddtext;
      if(this.cancel) return this.parseAttribute('cancel')+this.canceldaddtext;
      if(this.refuse) return this.parseAttribute('refuse')+this.refusedaddtext;
      if(this.refresh) return this.parseAttribute('refresh')+this.refreshaddtext;
      if(this.exit3) return this.parseAttribute('exit_car3')+this.exit3addtext;
      if(this.inventory) return this.parseAttribute('inventory_enter')+this.inventoryaddtext;
      if(this.inventory2) return this.parseAttribute('inventory_edit')+this.inventory2addtext;
      if(this.destroy) return this.$t('destroy')+this.destroyaddtext;
      if(this.move) return this.$t('move')+this.moveaddtext;
      if(this.upload) return this.$t('upload')+this.uploadaddtext;
      if(this.link_to_bill) return this.$t('link_to_bill');
      if(this.unlink) return this.$t('unlink');
      if(this.closesync) return this.$t('closesync');
      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
