import { render, staticRenderFns } from "./AddRequestNote.vue?vue&type=template&id=21bb7c03&scoped=true&"
import script from "./AddRequestNote.vue?vue&type=script&lang=js&"
export * from "./AddRequestNote.vue?vue&type=script&lang=js&"
import style0 from "./AddRequestNote.vue?vue&type=style&index=0&id=21bb7c03&prod&lang=css&"
import style1 from "./AddRequestNote.vue?vue&type=style&index=1&id=21bb7c03&prod&lang=sass&scoped=true&"
import style2 from "./AddRequestNote.vue?vue&type=style&index=2&id=21bb7c03&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bb7c03",
  null
  
)

export default component.exports