export default {
  service_request_id: 'معرف الطلب',
  plate: 'اللوحة',
  chassie: 'رقم الهيكل',
  type: 'النوع',
  request_type: 'نوع الطلب',
  model: 'الموديل',
  owner_name: 'اسم المالك',
  client_id: 'العميل',
  status: 'حالة الطلب',
  request_uploadid: 'رفع استمارة السيارة',
  service_fees: 'تكلفة الخدمة',
  requeststutas0:  "طلب جديد",
  requeststutas1:  "انتظار موافقة التكاليف",
  requeststutas2:  "مرفوض من قبل العميل",
  requeststutas3:  "قيد التنفيذ",
  requeststutas4:  "ملغي بطلب من العميل",
  requeststutas5:  "مكتمل التنفيذ",
};
