<template>
  <app-col dense class="pa-0 ma-0 text-center" style="text-align: center !important;" lg="12" md="12" sm="12">
    <v-edit-dialog
        ref="editSelectDialog"
        v-bind="$attrs"
        v-on="$listeners"
        :cancel-text="getCancelText"
        :large="large"
        :Select_Items="Select_Items"
        :persistent="persistent"
        :return-value.sync="inputValue"
        :save-text="getSaveText"
        :value-text.sync="valueText"
        @cancel="cancel"
        @close="close"
        @save="save"
        :rules="getRules">
        <div class="pa-0 ma-0 d-inline-block align-center">{{ getValueText }}</div>
        <v-btn width="32" height="32" v-if="TypeIcon2" :class="`app-dt-btn me-1 my-1 float-`+AppOpositeAlign" fab small>
          <v-icon :color="TypeIcon2Color" :size="24">{{ TypeIcon2 }}</v-icon>
        </v-btn>
        <v-btn width="32" height="32" v-if="TypeIcon" @click="openEditDialog" :class="`app-dt-btn me-1 my-1 float-`+AppOpositeAlign" fab small>
          <v-icon :color="TypeIconColor" :size="24">{{ TypeIcon }}</v-icon>
        </v-btn>
        <template v-slot:input>
          <template v-if="haveSlot">
            <slot name="input"></slot>
          </template>
          <template v-else>
            <app-container>
              <app-row danse>
                <app-auto-select
                  :ref="name"
                  :name="name"
                  :rules="getRules"
                  v-model="inputValue"
                  v-bind="$attrs"
                  :errors="componentErrors"
                  :items="Select_Items"/>
              </app-row>
            </app-container>
          </template>
          <slot v-bind="{errors}"></slot>
        </template>
    </v-edit-dialog>
  </app-col>
</template>
<script>
export default {
  name: 'DatatableEditSelectDialog',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text',
      validator(type) {
        const types = ['text', 'number', 'price', 'float'];
        const r = types.indexOf(type) >= 0;
        if(!r) {
          console.error('Available types is: ' + types.join(', '));
        }
        return r;
      }
    },
    persistent: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      require: true
    },
    Select_Items: {
      type: Array,
      require: true
    },
    name: {
      require: true
    },
    cancelText: {
      type: String,
      default: () => 'cancel'
    },
    saveText: {
      type: String,
      default: () => 'save'
    },
    valueText: {
      type: String,
      default: () => ''
    },
    TypeIcon: {
      type: String,
      default: () => undefined
    },
    TypeIcon2: {
      type: String,
      default: () => undefined
    },
    TypeIconColor: {
      type: String,
      default: () => 'success'
    },
    TypeIcon2Color: {
      type: String,
      default: () => 'success'
    },
    updateMethod: {
      type: Function,
      require: true
    },
    required: {
      type: Boolean,
      default: () => false
    },
    rules: {
      type: [Array, String],
      default: () => ''
    },
  },
  data() {
    return {
      inputValue: undefined,
      errors: {}
    };
  },
  methods: {
    iniComp() {
      if(this.haveErrors){}
      else {
        this.inputValue = this.value;
        this.ViewValueText = this.valueText;
      }
    },
    openEditDialog() {
      const ref = this.$refs.editSelectDialog;
      ref && (ref.isActive = !0);
    },
    updateValue() {
      this.$emit('input', this.inputValue);
    },
    saved() {
      this.$emit('refresh');
    },
    save() {
      let name = this.name;
      ['_to_yes', '_to_string', 'ToString'].forEach((v) => name.substr(-v.length) === v && (name = name.split(v)[0]));
      this.errors = {};
      if (this.updateMethod) {
        const params = {[this.name]: this.inputValue};
        this.updateMethod(this.item.id, params)
          .then(({data}) => {
            if (data && data.success === true) {
              const a = this.alertSuccess(data.message);
              if (data.data && data.data.user_type_to_string) {
                this.ShowValueText = data.data.user_type_to_string;
              }
              this.updateValue();
              this.errors = {};
              a.finally(() => this.saved());
            }
          })
          .catch(({response}) => {
            const {data} = response || {};
            // data && data.message && this.alertError(data.message);
            this.errors = data && data.errors || {};
            this.$nextTick(() => this.openEditDialog());
          });
      }
    },
    cancel() {
      this.$emit('cancel', this.item);
    },
    open() {
      this.iniComp();
      this.$emit('open', this.item);
    },
    close() {
      this.$emit('close', this.item);
      this.errors = {};
    }
  },
  mounted() {
    this.iniComp();
  },
  computed: {
    ParentWidth: {
      set(v){
        this.parent_width = v;
      },
      get(){
        return this.parent_width;
      }
    },
    MountWidth: {
      set(v){
        this.mount_width = v;
      },
      get(){
        return this.mount_width;
      }
    },
    haveErrors() {
      return this.componentErrors.length > 0;
    },
    componentErrors() {
      let name = this.name;
      ['_to_yes', '_to_string', 'ToString'].forEach((v) => name.substr(-v.length) === v && (name = name.split(v)[0]));
      return this.errors && name && this.errors[name] ? this.errors[name] : [];
    },
    componentName() {
      return `app-${this.type.toLowerCase()}-input`;
    },
    haveSlot() {
      return this.$scopedSlots['input'] !== undefined;
    },
    getValueText() {
      return this.ShowValueText;
    },
    getSaveText() {
      return this.parseAttribute(this.saveText);
    },
    getCancelText() {
      return this.parseAttribute(this.cancelText);
    },
    getRules() {
      let rules = this.rules || [];
      rules = typeof rules === 'string' ? rules.split('|') : rules;
      if(this.required === true && rules.indexOf('required') === -1) {
        rules.push('required');
      }
      return rules.join('|');
    },
    getErrorCount() {
      return this.errorCount === undefined ? 1 : (parseInt(this.errorCount) || 1);
    },
    getErrorMessages() {
      const provider = this.$refs.provider;
      let errors = [...this.errors];
      provider && (errors = [...errors, ...provider.errors]);

      return errors;
    },
    ShowValueText:{
      get(){
        return this.valueText!==''?this.valueText:this.value;
      },
      set(n){
        this.$emit('update:valueText', n);
      }
    },
  }
};
</script>
