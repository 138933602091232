/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import routes from '@routes/config';

export default [{
    path: routes.carCategory.index,
    name: routes.carCategory.index,
    component: () => import(/* webpackChunkName: "CarCategory"*/  '@components/CarCategory/Index')
}]
