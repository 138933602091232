export default{
    car: {
        index: 'car-index',
        client: 'car-client',
        waiting: 'car-waiting',
        warehouseindex: 'car-warehouseindex',
        sale: 'car-sale',
        after: 'car-after',
        after2: 'car-after2',
        add2sale: 'car-add2sale',
        stolen: 'car-Stolen',
    }
}
