export default {
    client_id:     'Client',
    template_date: 'Letter Date',
    part1:         'Before Vehicles Table',
    part2:         'After Vehicles Table',
    top_margin:    'top Margin (mm)',
    bottom_margin: 'bottom Margin (mm)',
    right_margin:  'right Margin (mm)',
    left_margin:   'left Margin (mm)',
    owner_name:    'Contact Name',
    real_owner_name:    'Owner Name',
    mobile:        'Addressed Number',
    left_address:  'Addressed address',
    km: 'ODO Meter',
    name: 'Client Name',
    DataCodes: 'DataCodes',
};
