import routes from '@routes/config';

export default [
    {
        path: routes.car.index,
        name: routes.car.index,
        component: () => import(/* webpackChunkName: "Car" */  '@components/Car/Index')
    },
    {
        path: routes.car.client,
        name: routes.car.client,
        component: () => import(/* webpackChunkName: "CarClient" */  '@components/Car/Client')
    },
    {
      path: routes.car.waiting,
      name: routes.car.waiting,
      component: () => import(/* webpackChunkName: "CarWaiting" */  '@components/Car/Waiting')
    },
    {
      path: routes.car.warehouseindex,
      name: routes.car.warehouseindex,
      component: () => import(/* webpackChunkName: "CarWarhouse" */  '@components/Car/Warehouse')
    },
    {
      path: routes.car.sale,
      name: routes.car.sale,
      component: () => import(/* webpackChunkName: "CarSale" */  '@components/Car/Sale')
    },
    {
      path: routes.car.after,
      name: routes.car.after,
      component: () => import(/* webpackChunkName: "CarAfter" */  '@components/Car/AfterSale')
    },
    {
      path: routes.car.after2,
      name: routes.car.after2,
      component: () => import(/* webpackChunkName: "CarAfter2" */  '@components/Car/AfterSale2')
    },
    {
      path: routes.car.add2sale,
      name: routes.car.add2sale,
      component: () => import(/* webpackChunkName: "CarAdd2Sale" */  '@components/Car/Add2Sale')
    },
    {
      path: routes.car.stolen,
      name: routes.car.stolen,
      component: () => import(/* webpackChunkName: "CarStolen" */  '@components/Car/Stolen')
    },
]
