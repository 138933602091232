
import {kebabCase} from 'lodash'

export default {
  methods: {
    route(name) {
      name = kebabCase(name)
      return {name};
    },
    isRoute(name) {
      return this.$route.name === name;
    }
  },
  computed: {
    AppName() {
      return process.env.MIX_APP_NAME
    },
    AppURL() {
      return process.env.APP_URL;
    },
    DATES_FORMATS() {
      return {
        'DATE_FORMAT':process.env.DATE_FORMAT,
        'DATE_FORMAT_MOMENTS':process.env.DATE_FORMAT_MOMENTS,
        'HIJRI_DATE_FORMAT':process.env.HIJRI_DATE_FORMAT,
        'HIJRI_DATE_FORMAT_MOMENTS':process.env.HIJRI_DATE_FORMAT_MOMENTS,
      }
    },
    AppLogo() {
      // console.log(this.themeColor);
      // return '';
      return require(`@images/${this.themeColor}.png`).default;
    },
  }
}
