/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
      car_id: 'car_id',
    car_cost_transaction_id: 'car_cost_transaction_id',
    history_date: 'history_date',
    cost_item_id: 'cost_item_id',
    amount: 'amount',
    old_status: 'old_status',
    new_status: 'new_status',
    action_type: 'action_type',

};
