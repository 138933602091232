

import Vue from "vue";
import {
  configure,
  extend,
  localize,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate/dist/vee-validate.full.esm";

require("./rules");


configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid"
  },
  bails: false,
  immediate: true,
  mode: "eager",
});
// Eager
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
export {
  ValidationProvider,
  ValidationObserver,
  localize,
  configure,
  extend,
  setInteractionMode
};
