/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    ref: 'Reference No',
    item_code: 'Transaction code',
    finance_account_id: 'Finance Account',
    item_type: 'Transaction type',
    item_type1: 'Payment',
    item_type2: 'Purchase',
    item_type3: 'Recover',
    item_type4: 'Revenue',
    item_type5: 'Refund',
};
