<template>
  <v-dialog scrollable class="ma-0 pa-0 mt-1" v-model="show" width="100$" max-width="100%" height="400" max-height="500">
    <v-card flat loader-height="30" tile>
      <v-toolbar class="justify-content-center" color="secondary" max-height="30" height="30">
        <v-toolbar-title v-text="parseAttribute('enter_car_in')"/>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <div class="pa-0 ma-0">
          <app-datatable vmaxwidth="100%" :options.sync="datatableOptions" dense style=" font-size: 0.875rem !important;"
            class="pa-0" height="200"
            ref="datatable2"
            :btnDialog="false"
            :headers="headers"
            :modalTitle="datatableDialogTitle"
            :url="tabUrl"
            v-model="SelectedItem"
            :single-select="true" search center>
            <template #filter="{datatableFilters}">
              <app-row class="pa-0 ma-0">
                <app-clients class="pa-0 px-1 ma-0" :clearable="true" v-model="datatableFilters.client_id" name="client_id" sm="6" md="3"/>
              </app-row>
            </template>
            <template #item.control="{item}">
              <app-dt-btn v-if="HasPermission('car_enter_this_car')" :showentertext="' '+parseAttribute('enter_this_car')" enter @click="CarEnterModalActivator(item)"/>
            </template>
          </app-datatable>
          <v-dialog class="ma-0 pa-0 mt-1" v-model="CarEnterDialog" v-if="CarEnterDialog" max-width="1024" height="600">
            <v-card flat loader-height="30" tile>
              <v-toolbar
                class="justify-content-center"
                color="secondary"
                max-height="30"
                height="30"
              >
                <v-toolbar-title v-text="parseAttribute('enter_this_car')"/>
              </v-toolbar>
              <v-card-text>
                <div class="pt-2">
                  <app-row dense class="px-1 pt-2">
                    <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="12" md="12">
                      <app-text-input :readonly="true" v-model="CarEnteritem.car_id" name="car_id"
                          sm="12" md="3" />
                      <app-auto-select @change="GetLocations" :items="WarehousesArray" v-model="CarEnteritem.warehouse_id" name="warehouse_id" sm="12" md="3" />
                      <app-auto-select :items="locations" v-model="CarEnteritem.storage_location_id" name="storage_location_id" sm="12" md="3" />
                      <app-number-input v-model="CarEnteritem.transportation_fees"
                          name="transportation_fees" sm="12" md="3" />
                    </v-col>
                  </app-row>
                </div>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer></v-spacer>
                <app-submit min-width="120" @click="submitEnterForm" :loading="formDialogLoading">{{ $t('save') }}</app-submit>
                <app-btn color="error" min-width="120" tooltip="close_window" @click="CloseCarEnterDialog">
                  {{ $t('close') }}
                </app-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
<!--        <app-submit :loading="formDialogLoading">{{ $t('save') }}</app-submit>-->
        <app-btn :loading="formDialogLoading" color="error" min-width="120" tooltip="close_window" @click="showData = false"            >
          {{ $t('close') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 0 10px 0 0 !important;
}
.v-text-field input{
  padding: 0;
}
</style>
<style
    lang="sass"
    scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1
</style>
<style>
.v-expansion-panel-header {
  padding: 8px 5px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  justify-content: center !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  height: auto !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height: auto !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
  border-bottom: thin solid #0000008a;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #c5c5c5;
}
.v-tab{
  padding: 0 10px !important;
  min-width: 35px !important;
}
.v-btn--fab.v-size--small {
  height: 35px !important;
  width: 35px !important;
}
.mhf-btn{
  background: -webkit-linear-gradient(top, rgb(28, 109, 249) 0%,rgb(22, 29, 165) 100%) !important;
}
</style>
<script>
import MetaInfoMixin from "@mixins/MetaInfoMixin";
import DatatableMixin from "@mixins/DatatableMixin";
import WarehousesSelect from "@components/base/resources/Warehouses";
import StorageLocationsSelect from "@components/base/resources/StorageLocations";
export default {
  name: 'EnterCarDialog',
  components: {StorageLocationsSelect, WarehousesSelect},
  helperApiName: 'car',
  mixins: [MetaInfoMixin, DatatableMixin],
  data() {
    const apiMethods = this.$api.methods[this.$options.helperApiName];
    return {
      apiMethods,
      headers: [
        {text: this.parseAttribute('id'), value: 'id', sortable: false, filterable: false},
        'client_id_to_string',
        'plate',
        'chassie',
        'request',
        'type',
        'model',
        'damage_type_id_to_string',
        'status_to_string',
        'control',
      ],
      CarsData: {},
      SelectedItem: {},
      CarEnteritem: {},
      locations: {},
      dialog: false,
      formDialogLoading: false,
      CarEnterDialog: false,
      tabUrl:'Panel/entercars',
      datatableOptions: {
        itemsPerPage: 5,
      },
    }
  },
  props: {
    show: {
      type: Boolean,
      required: !0
    },
  },
  computed: {
    showData: {
      set(n) {
        this.$emit('update:show', n);
      },
      get() {
        return this.show
      },
    },
    user: {
      get(){
        return this.current_user_data();
      }
    },
    WarehousesArray: {
      get(){
        return this.Warehouses;
      }
    },
  },
  methods: {
    GetLocations(){
      this.locations =[];
      this.$axios.get(`Panel/GetStorageLocationsByWareHouse`, {params: {
        warehouse_id:this.CarEnteritem.warehouse_id,
      }})
      .then(resdata => {
        this.locations = resdata.data.data;
      }).catch(errordata => {
        console.log(errordata.response);
      })
    },
    CarEnterModalActivator(item) {
      this.CarEnteritem = [];
      this.CarEnteritem.car_id = item.id;
      // this.CarEnteritem.warehouse_id = this.user.warehouse_id;
      this.CarEnterDialog = !this.CarEnterDialog;
    },
    submitEnterForm() {
      // console.log(this.CarEnteritem);
      let sendobject = {};
      sendobject.car_id = this.CarEnteritem.car_id;
      sendobject.warehouse_id = this.CarEnteritem.warehouse_id;
      sendobject.storage_location_id = this.CarEnteritem.storage_location_id;
      sendobject.transportation_fees = this.CarEnteritem.transportation_fees;
      // console.log(sendobject);
      this.$axios.post(`Panel/EnterThisCar`, sendobject,{})
        .then(data => {
          if (data.data && data.data.success) {
            this.alertSuccess(data.data.message);
            this.CarEnteritem = {};
            // this.GetCars();
            this.$nextTick(() => {
              this.CarEnteritem = [];
              // this.CarEnteritem['warehouse_id'] = this.user.warehouse_id;
              this.refreshDatatable();
              this.$emit('set-update_datatable');
              // this.GetCars();
              this.CarEnterDialog = !this.CarEnterDialog;
            });
          }
          else if(data.data && !data.data.success){
            this.alertError(data.data.message);
          }
        }).catch(errordata => {
        const {data} = errordata || {};
        data && data.message && this.alertError(data.message) && data.errors && this.setExcelErrors(data.errors);
        // this.getuploads_procces1(id);
        });
      this.refreshDatatable();
    },
    CloseCarEnterDialog() {
      this.CarEnteritem = [];
      // this.CarEnteritem['warehouse_id'] = this.user.warehouse_id;
      this.CarEnterDialog = !this.CarEnterDialog;
    },
  },
  watch:{
    showData(visible) {
      if (visible) {
        this.CarsData= [];
        this.refreshDatatable();
      }else{
        this.CarsData= [];
      }
    },
  },
}
</script>

