/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    ref: 'رقم المرجع',
    item_code: 'كود العملية',
    finance_account_id: 'رقم الحساب',
    item_type: 'نوع العملية',
    item_type1: 'صرف',
    item_type2: 'مشتريات',
    item_type3: 'استرداد',
    item_type4: 'مبيعات',
    item_type5: 'عكس',
};
