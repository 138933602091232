/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    user_id:                'User',
    trans_type:             'Transaction Type',
    trans_amount:           'Transaction Amount',
    trans_date:             'Transaction Date',
    document_upload:        'Upload Receipt',
    document_upload_date:   'Upload Receipt Date',
    document_approved_by:   'Approved By',
    document_approved_date: 'Approve Date',
    request_status:         'Request Status',

};
