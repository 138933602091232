export default {
  q: '؟',
  sar: 'SAR',
  false: 'معطل',
  true: 'مفعل',
  progress: 'التقدم...',
  id: '#',
  ar: 'العربية',
  link_to_bill: 'ربط مركبات',
  unlink: 'إلغاء ربط مركبات',
  closesync: 'إغلاق للمزامنة',
  en: 'English',
  printPDF: 'طباعة/PDF',
  confirm_letter: ' خطاب السحب بعد التوقيع',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  save: 'حفظ',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  upload: 'رفع',
  move: 'نقل',
  move_between: ' بين المستودعات',
  destroy: 'حذف',
  delete: 'حذف',
  regenerate: 'إعادة إنشاء',
  show: 'عرض',
  convert: 'تحويل',
  converted: 'تم التحويل',
  view: 'عرض',
  control: 'تحكم',
  none: 'لا يوجد',
  na: 'بدون',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  register: 'تسجيل',
  print: 'طباعة',
  hide: 'إخفاء',
  available: 'موجود',
  unavailable: 'غير موجود',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  exportPdf: 'تصدير البيانات PDF',
  add2salestring: 'إدخال للائحة البيع',
  add2auctionstring: 'إدخال للمزادات',
  model: 'الموديل',
  plate: 'اللوحة',
  exit4salestring: 'نقل/اسقاط الملكية',
  Paymentstring: 'تم السداد',
  exportExcel: 'تصدير البيانات Excel',
  other: 'أخرى',
  ImportExcel: 'استيراد البيانات من Excel',
  refreshTable: 'تحديث الصفحة',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  information: 'معلومات ',
  the_information: 'المعلومات ',
  company_dashboard_screen: 'شاشة متابعة المركبات',
  refreshData: 'تحديث البيانات',
  cars: 'المركبات',
  service_requests: 'الخدمات الإضافية',
  car: ' مركبة',
  setting: 'الضبط',
  month: 'شهر',
  non_defined: 'غير محدد',
  undefined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  qty: "الكمية",
  price: "السعر",
  total: "الاجمالي",
  to: "إلى",
  sr: " ريال",
  without: "بدون",
  rest: "متبقي",
  note: "ملاحظه: ",
  created_at: 'تاريخ الإضافة',
  updated_at: 'تاريخ التعديل',
  deleted_at: 'تاريخ الحذف',
  created_by: 'أضيف بواسطة',
  updated_by: 'التعديل بواسطة',
  deleted_by: 'حذف بواسطة',
  pending_by: 'طلب بواسطة',
  closed_by: 'إلغاء بواسطة',
  approved_by: 'الموافقة بواسطة',
  CarsWaiting: 'مركبات بانتظار السداد',
  CarsSale: 'مركبات تم بيعها بانتظار التنازل',
  CarsAfter: 'مركبات تم بيعها',
  CarsAfter2: 'مركبات تم التنازل عنها',
  CarsAdd2Sale: 'ادخال إلى لائحة البيع',
  CarsStolen: 'متابعة المركبات المسروقة',
  file_desc: 'وصف المرفق',
  all_notifications: 'عرض كل التنبيهات',
  usage_count: 'عداد الاستخدام',
};
