/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    car_id: 'car_id',
    transaction_date: 'التاريخ',
    cost_item_id: 'كود العملية',
    debit: 'مدين',
    credit: 'دائن',
    note2: 'ملاحظة',
    approves: 'التعميد',
    finance_account_id: 'رقم الحساب',

};
