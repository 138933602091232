/*
  SarhSoft Cross-PlatForm Engine Project
  Lnaguages: PHP, JavaScript, Node, Java.
  BackEnd: Laravel.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    user_id:                'المستخدم',
    trans_type:             'نوع العملية',
    trans_amount:           'القيمة',
    trans_date:             'تاريخ العملية',
    document_upload:        'رفع الإيصال',
    document_upload_date:   'تاريخ رفع الايصال',
    document_approved_by:   'تم التعميد بواسطة',
    document_approved_date: 'تاريخ التعميد',
    request_status:         'حالة الطلب',

};
