
<template>
  <app-row dense class="pa-0 ma-0 justify-content-end text-center">
    <v-card-text v-if="enablelabel" class="ma-0 pa-0 font-weight-bold primary white--text">
      {{enablelabel?getLabel:''}}
    </v-card-text>
    <v-card-text :class="`ma-0 pa-0 `+(enablelabel?`font-weight-bold text-h6`:``)">
      <v-edit-dialog
          ref="editDialog"
           v-bind="$attrs"
           v-on="$listeners"
          :cancel-text="getCancelText"
          :large="large"
          :persistent="persistent"
          :return-value.sync="inputValue"
          :save-text="getSaveText"
          @cancel="cancel"
          @close="close"
          @open="open"
          @save="save">
            {{ value }}
        <template v-slot:input>
          <template v-if="haveSlot">
            <slot name="input" ></slot>
          </template>
          <template v-else>
            <app-container>
              <app-row danse>
                <component
                    v-if="mask"
                    :is="componentName"
                    v-model="inputValue"
                    v-bind="$attrs"
                    :errors="componentErrors"
                    :name="name"
                    all="12"
                    v-mask="mask"
                    :label="getLabel"
                />
                <component
                    v-else
                    :is="componentName"
                    v-model="inputValue"
                    v-bind="$attrs"
                    :errors="componentErrors"
                    :name="name"
                    :label="getLabel"
                    all="12"
                />
              </app-row>
            </app-container>
          </template>
          <slot v-bind="{errors}"></slot>
        </template>
      </v-edit-dialog>
    </v-card-text>
  </app-row>
</template>

<script>
export default {
  name: 'DatatableEditDialog',
  props: {
    mask: {
      type: [String,Object,Array],
      default: () => undefined
    },
    value: {},
    type: {
      type: String,
      default: 'text',
      validator(type) {
        const types = ['text', 'number', 'price', 'float'];
        const r = types.indexOf(type) >= 0;
        if(!r) {
          console.error('Available types is: ' + types.join(', '));
        }
        return r;
      }
    },
    persistent: {
      type: Boolean,
      default: false
    },
    enablelabel: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      require: true
    },
    name: {
      require: true
    },
    cancelText: {
      type: String,
      default: () => 'cancel'
    },
    saveText: {
      type: String,
      default: () => 'save'
    },
    updateMethod: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      inputValue: undefined,
      errors: {}
    };
  },
  methods: {
    iniComp() {
      this.haveErrors || (this.inputValue = this.value);
    },
    openEditDialog() {
      const ref = this.$refs.editDialog;
      ref && (ref.isActive = !0);
    },
    updateValue() {
      this.$emit('input', this.inputValue);
    },
    saved() {
      this.$emit('refresh');
    },
    save() {
      this.errors = {};
      if(this.updateMethod) {
        const params = {[this.name]: this.inputValue};

        this.updateMethod(this.item.id, params)
          .then(({data}) => {
            if(data && data.success === true) {
              const a = this.alertSuccess(data.message);
              this.updateValue();
              this.errors = {};
              a.finally(() => this.saved());
            }
          })
          .catch(({response}) => {
            const {data} = response || {};
            // data && data.message && this.alertError(data.message);
            this.errors = data && data.errors || {};
            this.$nextTick(() => this.openEditDialog());
          }).finally(() => {
          // this.updateValue();
        })
      }

    },
    cancel() {
      this.$emit('cancel', this.item);
    },
    open() {
      this.iniComp();
      this.$emit('open', this.item);
    },
    close() {
      this.$emit('close', this.item);
      this.errors = {};
    }
  },
  mounted() {
    this.iniComp();
  },
  computed: {
    haveErrors() {
      return this.componentErrors.length > 0;
    },
    componentErrors() {
      let name = this.name;
      ['_to_yes', '_to_string', 'ToString'].forEach((v) => name.substr(-v.length) === v && (name = name.split(v)[0]));
      return this.errors && name && this.errors[name] ? this.errors[name] : [];
    },
    componentName() {
      return `app-${this.type.toLowerCase()}-input`;
    },
    haveSlot() {
      return this.$scopedSlots['input'] !== undefined;
    },
    getSaveText() {
      return this.parseAttribute(this.saveText);
    },
    getLabel() {
      if(this.enablelabel) {
        // console.log(this.parseAttribute(this.name));
        return this.parseAttribute(this.name);
      }
      return '';
    },
    getCancelText() {
      return this.parseAttribute(this.cancelText);
    }
  }
};
</script>
