<template>
  <v-dialog persistent class="ma-0 pa-0 mt-1" v-model="show" max-width="1024">
    <v-card flat loader-height="30" tile>
      <v-toolbar class="justify-content-center" color="secondary" max-height="30" height="30">
        <v-toolbar-title v-text="$t('show')+' '+parseAttribute('service_request_details')"/>
      </v-toolbar>
      <v-card-text>
        <div class="pt-2">
          <v-tabs maxHeight="30" minHeight="30" height="30" style="box-shadow: #00000033 0 2px 4px, #00000024 0 4px 5px 0, #0000001f 0 1px 10px 0;">
            <v-tab :active-class="activeClasses()" @click="service_requestdetails=true;service_requestattachments=false;service_requestnotes=false;" style="background-color: #0277bd !important; border-radius: 5px; color:#ffffff;border-color: #0277bd !important;" dense>{{ parseAttribute('service_request_details') }}</v-tab>
            <v-tab :active-class="activeClasses()" @click="service_requestnotes=true;service_requestattachments=false;service_requestdetails=false;" class="ms-1" style="min-width:0 !important; background-color: #0277bd !important; border-radius: 5px; color: #ffffff; border-color: #0277bd !important;" dense>{{parseAttribute('car_upadates')}}&nbsp;&nbsp;
              <app-tooltip :text="parseAttribute('add_note')">
                <v-btn style="color: white !important; background-color: #0277bd !important; padding: 0 5px !important;min-height:30px!important;max-height:30px !important;max-width:0px !important;min-width:0 !important;"></v-btn>
              </app-tooltip>
              <v-btn v-if="ServiceRequestshowSelecteditem.id" @click="showaddnote = !showaddnote" style="color: white !important; background-color: #0277bd !important; padding: 0 5px !important;min-height:30px!important;max-height:30px !important;max-width:0px !important;min-width:0 !important;"><v-icon> add </v-icon></v-btn>
            </v-tab>
            <v-tab :active-class="activeClasses()" @click="service_requestattachments=true;service_requestdetails=false;service_requestnotes=false;" class="ms-1" style="min-width:0 !important; background-color: #0277bd !important; border-radius: 5px; color: #ffffff; border-color: #0277bd !important;" dense>{{parseAttribute('attachments')}}
              <v-badge v-if="(ServiceRequestshowSelecteditem.request_uploadid===0||(ServiceRequestshowSelecteditem.request_saledonedocument===0&&ServiceRequestshowSelecteditem.status===5))" color="error" content="!"></v-badge>
            </v-tab>
          </v-tabs>
          <app-row dense class="px-1 pt-2" v-show="service_requestdetails">
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('service_request_id')}}: {{ServiceRequestshowSelecteditem.id}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('client_id')}}: {{ServiceRequestshowSelecteditem.client_id_to_string}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('request_type')}}: {{ServiceRequestshowSelecteditem.request_type_to_string}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('owner_name')}}: {{ServiceRequestshowSelecteditem.owner_name}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('plate')}}: {{ServiceRequestshowSelecteditem.plate}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('chassie')}}: {{ServiceRequestshowSelecteditem.chassie}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('serial_no')}}: {{ServiceRequestshowSelecteditem.serial_no}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('type')}}: {{ServiceRequestshowSelecteditem.type}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('model')}}: {{ServiceRequestshowSelecteditem.model}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('service_fees')}}: {{ServiceRequestshowSelecteditem.service_fees}} {{parseAttribute('sr')}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('created_at')}}: {{ServiceRequestshowSelecteditem.created_at_to_string}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('updated_at')}}: {{ServiceRequestshowSelecteditem.updated_at_to_string}}</v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="6" md="3">{{parseAttribute('status')}}: {{ServiceRequestshowSelecteditem.status_to_string}}</v-col>
          </app-row>
          <app-row dense class="px-1 pt-2" v-show="service_requestnotes">
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="12" md="12">
              <v-data-table style=" font-size: 0.875rem !important;" height="200" :items="ServiceRequestshowSelecteditemnotes" item-key="id" :server-items-length="ServiceRequestshowSelecteditemnotes.length" :headers="notesheaders" class="elevation-1" :options.sync="datatableOptions" :items-per-page="5">
                <template v-slot:item.user_id_to_string="{ item }">
                  {{item.user_id_to_string}}
                </template>
              </v-data-table>
            </v-col>
          </app-row>
          <app-row dense class="px-1 pt-2" v-show="service_requestattachments">
            <v-col class="pa-0 ma-0" style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="12" md="12">
              <app-row class="pa-0 ma-0" v-if="ServiceRequestshowSelecteditem.request_uploadid===0">
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <app-text-input :readonly="true" v-model="Desc2" :label="parseAttribute('carid')" :clearable="false" :counter="false" autocomplete="off" class="pa-0 ma-0" name="desc2" chips counter show-size small-chips truncate-length="15" :value="parseAttribute('carid')"></app-text-input>
                </v-col>
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <v-file-input class="pa-0 ma-0 mt-1" ref="file2" id="file2" name="file_desc" chips counter show-size small-chips truncate-length="15"/>
                </v-col>
                <v-col dense sm="12" md="2" class="pa-0 ma-0">
                  <app-row dense class="pa-0 ma-0 justify-center">
                    <v-btn :loading="UploadLoading" dense color="primary" class="pa-0 ma-0 px-1 mt-4" @click="upload_procces2(ServiceRequestshowSelecteditem.id)">{{$t('upload')}} {{parseAttribute('carid')}}</v-btn>
                  </app-row>
                </v-col>
              </app-row>
              <app-row class="pa-0 ma-0" v-if="ServiceRequestshowSelecteditem.request_authorizationletter===0">
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <app-text-input :readonly="true" v-model="Desc4" :label="parseAttribute('authorizationletter')" :clearable="false" :counter="false" autocomplete="off" class="pa-0 ma-0" name="desc2" chips counter show-size small-chips truncate-length="15" :value="parseAttribute('authorizationletter')"></app-text-input>
                </v-col>
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <v-file-input class="pa-0 ma-0 mt-1" ref="file4" id="file4" name="file_desc" chips counter show-size small-chips truncate-length="15"/>
                </v-col>
                <v-col dense sm="12" md="2" class="pa-0 ma-0">
                  <app-row dense class="pa-0 ma-0 justify-center">
                    <v-btn :loading="UploadLoading" dense color="primary" class="pa-0 ma-0 px-1 mt-4" @click="upload_procces4(ServiceRequestshowSelecteditem.id)">{{$t('upload')}} {{parseAttribute('authorizationletter')}}</v-btn>
                  </app-row>
                </v-col>
              </app-row>
              <app-row class="pa-0 ma-0" v-if="ServiceRequestshowSelecteditem.request_saledonedocument===0&&ServiceRequestshowSelecteditem.status===5">
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <app-text-input :readonly="true" v-model="Desc1" :label="parseAttribute('car_saledonedocument')" :clearable="false" :counter="false" autocomplete="off" class="pa-0 ma-0" name="desc1" chips counter show-size small-chips truncate-length="15" :value="parseAttribute('car_saledonedocument')"></app-text-input>
                </v-col>
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <v-file-input class="pa-0 ma-0 mt-1" ref="file1" id="file1" name="file_desc" chips counter show-size small-chips truncate-length="15"/>
                </v-col>
                <v-col dense sm="12" md="2" class="pa-0 ma-0">
                  <app-row dense class="pa-0 ma-0 justify-center">
                    <v-btn :loading="UploadLoading" dense color="primary" class="pa-0 ma-0 px-1 mt-4" @click="upload_procces1(ServiceRequestshowSelecteditem.id)">{{$t('upload')}} {{parseAttribute('car_saledonedocument')}}</v-btn>
                  </app-row>
                </v-col>
              </app-row>
              <app-row class="pa-0 ma-0">
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <app-text-input v-model="Desc3" :label="parseAttribute('otherattach')" :clearable="false" :counter="false" autocomplete="off" class="pa-0 ma-0" name="desc3" chips counter show-size small-chips truncate-length="15" :value="parseAttribute('otherattach')"></app-text-input>
                </v-col>
                <v-col dense sm="12" md="5" class="pa-0 ma-0">
                  <v-file-input class="pa-0 ma-0 mt-1" ref="file3" id="file3" name="file_desc" chips counter show-size small-chips truncate-length="15"/>
                </v-col>
                <v-col dense sm="12" md="2" class="pa-0 ma-0">
                  <app-row dense class="pa-0 ma-0 justify-center">
                    <v-btn :loading="UploadLoading" dense color="primary" class="pa-0 ma-0 px-1 mt-4" @click="upload_procces3(ServiceRequestshowSelecteditem.id)">{{$t('upload')}}</v-btn>
                  </app-row>
                </v-col>
              </app-row>
            </v-col>
            <v-col style="border: thin solid #0000001f !important; font-size: 0.875rem !important;" dense sm="12" md="12">
              <v-data-table style=" font-size: 0.875rem !important;" height="auto" :items="ServiceRequestshowSelecteditemattachments" item-key="id" :server-items-length="ServiceRequestshowSelecteditemattachments.length" :headers="attachmentsheads" class="elevation-1" :options.sync="datatableOptions" :items-per-page="5">
                <template #item.id="{item}">
                  {{item.index}}
                </template>
                <template #item.download="{item}">
                  <app-btn-med large @click="download2(item.url,item.mime_type,item.original_filename)">
                    {{parseAttribute('download')}}</app-btn-med>
                </template>
              </v-data-table>
            </v-col>
          </app-row>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-btn color="error" min-width="120" tooltip="close_window" @click="showData = false">
          {{ $t('close') }}
        </app-btn>
      </v-card-actions>
    </v-card>
    <AddRequestNoteDialog :show.sync="showaddnote" :service_request_id.sync="to_showServiceRequest_id" :service_request_status.sync="to_showServiceRequest_status"/>
  </v-dialog>
</template>
<style>
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 0 10px 0 0 !important;
}
.v-text-field input{
  padding: 0;
}
</style>
<style
    lang="sass"
    scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1
</style>
<style>
.v-expansion-panel-header {
  padding: 8px 5px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  justify-content: center !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  height: auto !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height: auto !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
  border-bottom: thin solid #0000008a;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #c5c5c5;
}
.v-tab{
  padding: 0 10px !important;
  min-width: 35px !important;
}
.v-btn--fab.v-size--small {
  height: 35px !important;
  width: 35px !important;
}
.mhf-btn{
  background: -webkit-linear-gradient(top, rgb(28, 109, 249) 0%,rgb(22, 29, 165) 100%) !important;
}
</style>
<script>
import MetaInfoMixin from "@mixins/MetaInfoMixin";
import DatatableMixin from "@mixins/DatatableMixin";
import AddRequestNoteDialog from "@components/base/dialogs/AddRequestNote";
export default {
  name: "ViewServiceRequestsDialog",
  helperApiName: 'serviceRequest',
  components: {AddRequestNoteDialog},
  mixins: [MetaInfoMixin, DatatableMixin],
  data() {
    const apiMethods = this.$api.methods[this.$options.helperApiName];
    return {
      apiMethods,
      headers: [
        'id',
        'plate',
        'type',
        'request_type_to_string',
        'status_to_string',
        'control',
      ],
      notesheaders: [
        {text: this.parseAttribute('note_date'), value: 'note_date_to_string', sortable: false, filterable: false},
        {text: this.parseAttribute('user_id'), value: 'user_id_to_string', sortable: false, filterable: false},
        {text: this.parseAttribute('note_text'), value: 'note_text', sortable: false, filterable: false},
        {text: this.parseAttribute('new_status'), value: 'new_status_to_string', sortable: false, filterable: false},
      ],
      attachmentsheads: [
        {text: '#', value: 'id', sortable: false, filterable: false},
        {text: this.parseAttribute('attachment_type'), value: 'name', sortable: false, filterable: false},
        {text: this.parseAttribute('download_view_attachment'), value: 'download', sortable: false,
          filterable:
            false},
      ],
      datatableOptions: {
        itemsPerPage: 5,
      },
      ServiceRequestshowSelecteditem: [],
      ServiceRequestshowSelecteditemnotes: [],
      ServiceRequestshowSelecteditemattachments: [],
      ServiceRequestShowdialog: null,
      service_requestdetails: true,
      service_requestnotes: false,
      service_requestattachments: false,
      Clientadddialog: !1,
      modalc: !1,
      showaddnote: false,
      ClientaddformDialogLoading: false,
      ClientaddItem: {},
      ClientaddItemarray: [],
      ClientaddformErrors: {},
      to_showServiceRequest_id: 0,
      to_showServiceRequest_status: 0,
      Desc1: this.parseAttribute('car_saledonedocument'),
      Desc2: this.parseAttribute('carid'),
      Desc3: '',
      Desc4: this.parseAttribute('car_authorizationletter'),
      UploadLoading: !1,
    }
  },
  props: {
    show: {
      type: Boolean,
      required: !0
    },
  },
  computed: {
    showData: {
      set(n) {
        this.$emit('update:show', n);
      },
      get() {
        return this.show
      },
    },
  },
  watch:{},
  methods: {
    GetServiceRequestData(id=0) {
      this.ServiceRequestshowSelecteditem = [];
      this.ServiceRequestshowSelecteditemnotes = [];
      this.ServiceRequestshowSelecteditemattachments = [];
      this.service_requestdetails=true;
      this.service_requestattachments=false;
      this.service_requestnotes=false;
      this.apiMethods.show(id).then((r) => {
        this.ServiceRequestshowSelecteditem = r.data.data;
        this.to_showServiceRequest_id = r.data.data.id;
        this.to_showServiceRequest_status = r.data.data.status;
        this.ServiceRequestshowSelecteditemnotes = r.data.data.service_request_notes||[];
        this.ServiceRequestshowSelecteditemattachments = r.data.data.service_request_attachments||[];
      });
    },
    upload_procces1(id){
      if(this.UploadLoading) {return;}
      this.UploadLoading =!0;
      var formData = new FormData();
      var imagefile = document.querySelector('#file1');
      formData.append("file", imagefile.files[0]);
      formData.append("tag", 'request_saledonedocument');
      formData.append("desc", this.Desc1);
      formData.append("model_id", id);
      this.$axios.post(`Panel/ServiceAddByClient`, formData,{headers: {'Content-Type': 'multipart/form-data'}},)
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.$refs.file1.reset();
                this.Desc1 = this.parseAttribute('car_saledonedocument');
                this.Desc2 = this.parseAttribute('carid');
                this.Desc3 = '';
                this.Desc4 = this.parseAttribute('car_authorizationletter');
                this.refreshDatatable();
                this.getuploads_procces1(id);
                this.UploadLoading =!1;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
              this.UploadLoading =!1;
            }
          }).catch(errordata => {
        const {data} = errordata || {};
        data && data.message && this.alertError(data.message) && data.errors && this.setExcelErrors(data.errors);
        this.UploadLoading =!1;
      });
    },
    upload_procces2(id){
      if(this.UploadLoading) {return;}
      this.UploadLoading =!0;
      var formData = new FormData();
      var imagefile = document.querySelector('#file2');
      formData.append("file", imagefile.files[0]);
      formData.append("tag", 'request_uploadid');
      formData.append("desc", this.Desc2);
      formData.append("model_id", id);
      this.$axios.post(`Panel/ServiceAddByClient`, formData,{headers: {'Content-Type': 'multipart/form-data'}},)
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.$refs.file2.reset();
                this.Desc1 = this.parseAttribute('car_saledonedocument');
                this.Desc2 = this.parseAttribute('carid');
                this.Desc3 = '';
                this.Desc4 = this.parseAttribute('car_authorizationletter');
                this.refreshDatatable();
                this.getuploads_procces1(id);
                this.UploadLoading =!1;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
              this.UploadLoading =!1;
            }
          }).catch(errordata => {
        const {data} = errordata || {};
        data && data.message && this.alertError(data.message) && data.errors && this.setExcelErrors(data.errors);
        this.UploadLoading =!1;
      });
    },
    upload_procces3(id){
      if(this.UploadLoading) {return;}
      this.UploadLoading =!0;
      var formData = new FormData();
      var imagefile = document.querySelector('#file3');
      formData.append("file", imagefile.files[0]);
      formData.append("tag", 'service_request_all');
      formData.append("desc", this.Desc3);
      formData.append("model_id", id);
      this.$axios.post(`Panel/ServiceAddByClient`, formData,{headers: {'Content-Type': 'multipart/form-data'}},)
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.$refs.file3.reset();
                this.Desc1 = this.parseAttribute('car_saledonedocument');
                this.Desc2 = this.parseAttribute('carid');
                this.Desc3 = '';
                this.Desc4 = this.parseAttribute('car_authorizationletter');
                this.refreshDatatable();
                this.getuploads_procces1(id);
                this.UploadLoading =!1;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
              this.UploadLoading =!1;
            }
          }).catch(errordata => {
        const {data} = errordata || {};
        data && data.message && this.alertError(data.message) && data.errors && this.setExcelErrors(data.errors);
        this.UploadLoading =!1;
      });
    },
    upload_procces4(id){
      if(this.UploadLoading) {return;}
      this.UploadLoading =!0;
      var formData = new FormData();
      var imagefile = document.querySelector('#file4');
      formData.append("file", imagefile.files[0]);
      formData.append("tag", 'request_authorizationletter');
      formData.append("desc", this.Desc4);
      formData.append("model_id", id);
      this.$axios.post(`Panel/ServiceAddByClient`, formData,{headers: {'Content-Type': 'multipart/form-data'}},)
          .then(data => {
            if (data.data && data.data.success) {
              this.alertSuccess(data.data.message);
              this.$nextTick(() => {
                this.$refs.file4.reset();
                this.Desc1 = this.parseAttribute('car_saledonedocument');
                this.Desc2 = this.parseAttribute('carid');
                this.Desc3 = '';
                this.Desc4 = this.parseAttribute('car_authorizationletter');
                this.refreshDatatable();
                this.getuploads_procces1(id);
                this.UploadLoading =!1;
              });
            }
            else if(data.data && !data.data.success){
              this.alertError(data.data.message);
              this.UploadLoading =!1;
            }
          }).catch(errordata => {
        const {data} = errordata || {};
        data && data.message && this.alertError(data.message) && data.errors && this.setExcelErrors(data.errors);
        this.UploadLoading =!1;
      });
    },
    getuploads_procces1(val){
      this.service_requestattachments = {};
      this.$axios.get(`Panel/GetServiceRequestFiles`, {params: {model_id: val}})
        .then(resdata => {
          this.service_requestattachments = resdata.data.data;
        }).catch(errordata => {
        console.log(errordata.response);
      })
    },
    clickCModalActivator() {
      this.modalc = !this.modalc;
    },
    download(url,type,name){
      this.$axios.get(url, { responseType: 'blob' })
          .then(response => {
            let blob = new Blob([response.data], { type: type })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = name
            link.click()
          }).catch(console.error)
    },
    download2(url,type,name){
      this.$axios.get(url, { responseType: 'blob' })
          .then(response => {
            let blob = new Blob([response.data], { type: type })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = name
            link.click()
          }).catch(console.error)
    },
    ServiceRequestShowModalActivator(item) {
      this.service_requestdetails=true;
      this.service_requestattachments=false;
      this.service_requestnotes=false;
      this.ServiceRequestShowdialog = !this.ServiceRequestShowdialog;
    },
    closeServiceRequestShowdialog() {
      this.ServiceRequestshowSelecteditem = [];
      this.ServiceRequestshowSelecteditemnotes = [];
      this.ServiceRequestshowSelecteditemattachments = [];
      this.ServiceRequestShowdialog = !1;
    },
    activeClasses() {
      return [
        'active-item',
        'text--secondary',
        this.themeLight ? 'darken-3' : 'lighten-3'
      ].join(' ');
    },
  },
}
</script>

