export default {
    plate: 'اللوحة',
    chassie: 'الهيكل',
    request: 'المطالبة',
    type: 'النوع',
    model: 'الموديل',
    warehouse_id: 'المستودع',
    warehouse: 'المستودع',
    storage_location_id: 'موقع التخزين',
    owner_name1: 'اسم المخاطب',
    owner_name: 'اسم المخاطب',
    real_owner_name: 'اسم المالك',
    insurance_name: 'اسم المؤمن له',
    left_address: 'عنوان السحب',
    km: 'عداد الكيلومترات',
    invoice_number: 'رقم الفاتورة',
    invoice_price: 'سعر الشراء',
    invoice_price_deduct_missing: 'سعر الشراء مع خصم النواقص',
    city_id: 'المدينة',
    color: 'اللون',
    car_category_id: 'فئة المركبة',
    client_id: 'العميل',
    auction_id: 'المزاد',
    auction_no: 'الرقم في المزاد',
    damage_type_id: 'الضرر',
    store_damage_type_id: 'ضرر المتجر',
    trans_request: 'طلب السحب',
    trans_request_date: 'تاريخ السحب',
    check_in_date: 'تاريخ الدخول',
    transporter_id: 'الناقل',
    transporter: 'الناقل',
    transporter_driver_id: 'سائق الناقل',
    upload_leftletter: 'رفع خطاب السحب',
    upload_authorizationletter: 'رفع خطاب التفويض',
    upload_carid: 'رفع استمارة المركبة',
    upload_carphoto: 'رفع صورة المركبة',
    upload_exitdocument: 'رفع خطاب استرجاع المركبة',
    upload_saledocument: 'رفع خطاب التنازل',
    upload_saledonedocument: 'رفع اثبات نقل/اسقاط',
    upload_all: 'رفع مرفقات أخرى للمركبة',
    leftletter: 'خطاب السحب',
    inspection_report: 'تقرير فحص المركبة',
    authorizationletter: 'خطاب التفويض',
    stolendocument: 'بلاغ السرقة',
    leftletter2: 'خطاب السحب بعد التوقيع',
    car_leftletter: 'خطاب السحب',
    car_authorizationletter: 'خطاب التفويض',
    carid: 'استمارة المركبة',
    car_carid: 'استمارة المركبة',
    carphoto: 'صورة المركبة',
    storephoto: 'صورة متجر',
    car_carphoto: 'صورة المركبة',
    exitdocument: 'خطاب استرجاع المركبة',
    car_exit: 'إخراج مركبة',
    car_exitdocument: 'خطاب استرجاع المركبة',
    otherattach: 'مرفقات أخرى',
    car_saledocument: 'خطاب تنازل',
    car_saledonedocument: 'اثبات نقل/اسقاط',
    car_otherattach: 'مرفقات أخرى',
    carkey: 'مفتاح المركبة',
    leftletter_date: 'تاريخ استلام خطاب السحب',
    inventory: 'جرد المركبة',
    transportation_fees: 'رسوم النقل',
    excelimport: 'ملف الإكسل',
    exceltemplate: 'قالب ملف الإكسل',
    check_out_date: 'تاريخ الخروج',
    attachment_type: 'نوع المرفق',
    car_details: 'بيانات المركبة',
    service_request_details: 'بيانات الطلب',
    receiver_details: 'بيانات التواصل',
    number: ' في مزاد رقم ',
    noperm: 'ليس لديك صلاحيات الوصول!!',
    request_new: 'طلب سحب',
    requests_new: 'طلبات سحب',
    car_checkinout: 'سجل دخول/خروج المركبة',
    check_date: 'تاريخ السجل',
    check_type: 'نوع السجل',
    car_upadates: 'تحديثات وتنبيهات المركبة',
    update_date: 'تاريخ التحديث',
    update_message: 'التحديث/التنبية',
    update_status: 'حالة التحديث',
    upload_leftletter_date: 'تاريخ استلام خطاب السحب',
    enter_car_in: 'إدخال مركبة للمستودع',
    exit_car: 'خروج للبيع',
    exit_car2: 'خروج بخطاب',
    exit_car3: 'خروج للتقدير',
    attachments: 'مرفقات المركبة',
    storeattachments: 'المتجر',
    store_brief: 'ملخص',
    costs: 'التكاليف',
    expens: 'المصروفات',
    back_to_car: 'العودة لبيانات المركبة',
    add_note: 'إضافة تحديث',
    note_type: 'نوع التنبيه',
    note_notify: 'تنبيه بالبريد الإلكتروني',
    assign_driver: 'تحديد سائق',
    assign_auction: 'تحديد مزاد',
    batchModalTitle: 'إدخال رقم الدفعة (اختياري)',
    auctionModalTitle: 'إدخال للمزاد',
    batch_no: 'رقم الدفعة',
    serial_no: 'الرقم التسلسلي',
    refuse_trans: 'تحديث بتعذر النقل',
    refuse_sale: 'تحديث بتعذر نقل الملكية',
    refuse_reason: 'سبب التعذر في النقل',
    re_trans: 'تحديث طلب إعادة النقل',
    request_exit2: 'طلب استرداد',
    request_exit: 'طلب استرداد المركبة',
    cancel_request_exit: 'إلغاء طلب استرداد المركبة',
    re_sale: 'تحديث طلب إعادة نقل الملكية',
    request_cancel: 'تحديث طلب إلغاء السحب',
    canceled: 'تحديث إلغاء طلب السحب',
    done_sale: 'تحديث تم نقل الملكية',
    done_drop: 'تحديث تم إسقاط الملكية',
    inventory_note: 'تحديث جرد مركبة',
    inventory_enter: 'جرد المركبة',
    inventory_edit: 'تعديل جرد المركبة',
    inventory_good: 'لايوجد بها نواقص',
    missing_documents: 'نواقص:',
    normal_note: 'تحديث عام',
    enter_this_car: 'إدخال المركبة',
    printPDF: 'طباعة/PDF',
    notetranstitle: 'مركبة بانتظار النقل',
    notetransmessage1: 'المركبة رقم ',
    notetransmessage2: ' بانتظار النقل',
    noteaddtitle: 'إضافة مركبة',
    noteaddmessage1: 'تم إضافة المركبة رقم ',
    noteaddmessage2: 'من العميل ',
    noteaddclienttitle: 'تنبيه هام!!',
    noteaddclientmessage1: 'لن يتم تحويل المركبة لوحة رقم ',
    noteaddclientmessage2: ' للناقل الا بعد رفع خطاب السحب الخاص بها',
    noteaddnotemessage: 'تم اضافة تخديث عام على المركبة رقم ',
    notenoterefusemessage: 'تعذر نقل المركبة رقم ',
    notenote2foundedmessage: 'تم العثور على المركبة رقم ',
    noteretransmessage: 'تم طلب إعادة نقل المركبة رقم ',
    noteentermessage1: 'تم إدخال المركبة رقم ',
    noteentermessage2: 'للمستودع ',
    noteaddnotetitle: ' تحديث عام للمركبة',
    noterefusetitle: 'تعذر نقل المركبة',
    noteretranstitle: 'طلب إعادة نقل',
    noteentertitle: 'إدخال مركبة للمستودع',
    user: 'المستخدم',
    exit_car2_note: 'ملاحظات اخراج مركبة بخطاب',
    car_id: 'معرف المركبة',
    show_in_store:  "عرض بالمتجر",
    request_status:  "حالة الطلب",
    market_price: "القيمة التامينية/السوقية",
    transfer_evaluate_amount: "السحب والتقدير",
    other_amount: "اخرى مدفوعات",
    client_tolerance_amount: "التحمل",
    depreciation_amount: "الاستهلاك",
    missing_parts_amount: "نواقص قطع",
    client_other_amount: "اخرى مستردات",
    client_total_paid: "اجمالي مدفوعات العميل",
    total_compensation: "اجمالي التعويض",
    client_total_refund: "اجمالي مستردات العميل",
    missing_parts_deduct: "خصم النواقص؟",
    carrequeststutas0:  "تحت الإجراء",
    carrequeststutas1:  "تم العثور على المركبة",
    carrequeststutas2:  "المركبة مباعة / مسقطة",
    carrequeststutas3:  "تعذر العثور على المركبة",
    client_belong:  "عميل تابع لـ",
    client_belong1:  "نسايم الشروق",
    client_belong2:  "حراج1",
    carstutas0:  "طلب جديد",
    carstutas1:  "لدى الناقل",
    carstutas2:  "تعذر استلام المركبة",
    carstutas3:  "جاري النقل",
    carstutas4:  "في المستودع",
    carstutas5:  "خروج للتقدير",
    carstutas6:  "في المستودع/بالمزاد",
    carstutas7:  "تم البيع",
    carstutas8:  "خروج بخطاب",
    carstutas9:  "بانتظار السداد",
    carstutas10: "تم البيع|بانتظارالنقل/الاسقاط",
    carstutas11: "تعذر اسقاط/نقل الملكية",
    carstutas12: "طلب سحب ملغي",
    carstutas13: "طلب إلغاء السحب",
};
