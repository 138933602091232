export default {
    client_id:   'العميل',
    template_date: 'تاريخ القالب',
    part1: 'ما قبل جدول المركبات',
    part2: 'ما بعد جدول المركبات',
    top_margin: 'الحد العلوي للصفجة (مم)',
    bottom_margin: 'الحد السفلي للصفجة (مم)',
    right_margin: 'الحد اليمين للصفجة (مم)',
    left_margin: 'الحد السيار للصفجة (مم)',
    owner_name: 'اسم المخاطب',
    real_owner_name: 'اسم المالك',
    mobile: 'جوال المخاطب',
    left_address: 'عنوان السحب',
    km: 'عداد الكيلومترات',
    name: 'اسم العميل',
    DataCodes: 'أكواد البيانات',
};
