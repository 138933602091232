<template>
  <ValidationObserver :ref="name" v-slot="v" v-bind="$attrs" v-on="$listeners">
    <v-form @submit.prevent="v.handleSubmit(() => submit(v))">
      <slot v-bind="v"></slot>
    </v-form>
  </ValidationObserver>
</template>
<style>
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 0 10px 0 0 !important;
}
.v-text-field input{
  padding: 0;
}
</style>
<style
    lang="sass"
    scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1
</style>
<style>
.v-expansion-panel-header {
  padding: 8px 5px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  justify-content: center !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  height: auto !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height: auto !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
  border-bottom: thin solid #0000008a;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #c5c5c5;
}
.v-tab{
  padding: 0 10px !important;
  min-width: 35px !important;
}
.v-btn--fab.v-size--small {
  height: 35px !important;
  width: 35px !important;
}
.mhf-btn{
  background: -webkit-linear-gradient(top, rgb(28, 109, 249) 0%,rgb(22, 29, 165) 100%) !important;
}
</style>
<script>
export default {
  name: 'Form',
  props: {
    submit: {
      type: Function,
      default: () => () => null
    },
    name: {
      type: String,
      default: () => 'form'
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    errors: {
      deep: !0,
      immediate: !0,
      handler(errors, old) {this.setErrors(errors);}
    }
  },
  computed: {},
  methods: {
    reset(...args) {this.$refs[this.name] && this.$refs[this.name].reset(...args);},
    validate(...args) {this.$refs[this.name] && this.$refs[this.name].validate(...args);},
    setErrors(errors) {this.$refs[this.name] && this.$refs[this.name].setErrors(errors);}
  }
};
</script>
