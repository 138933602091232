<template>
  <v-dialog persistent class="ma-0 pa-0 mt-1" v-model="show" max-width="1024">
    <v-card flat loader-height="30" tile>
      <v-toolbar class="justify-content-center" color="secondary" max-height="30" height="30">
        <v-toolbar-title v-text="$t('show')+' '+parseAttribute('car_details')"/>
      </v-toolbar>
      <v-card-text>
        <div class="pt-2">
            <app-row>
              <app-auto-select :items="note_types()" v-model="selectedItem.note_type" name="note_type" rules="required" sm="12" md="6"/>
              <app-switcher v-if="selectedItem.note_type===2" v-model="selectedItem.with_notify" name="with_notify" sm="12" md="6"/>
              <app-textarea-input v-model="selectedItem.note_text" name="note_text" sm="12" md="12" rules="required"/>
            </app-row>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-submit @click="submitNoteForm" :loading="formDialogLoading">{{ $t('save') }}</app-submit>
        <app-btn :loading="formDialogLoading" color="error" min-width="120" tooltip="close_window" @click="showData = false"            >
          {{ $t('close') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 0 10px 0 0 !important;
}
.v-text-field input{
  padding: 0;
}
</style>
<style
    lang="sass"
    scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: 0
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1
</style>
<style>
.v-expansion-panel-header {
  padding: 8px 5px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-application--is-rtl .v-messages {
  text-align: center;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  justify-content: center !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  height: auto !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height: auto !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
  border-bottom: thin solid #0000008a;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #c5c5c5;
}
.v-tab{
  padding: 0 10px !important;
  min-width: 35px !important;
}
.v-btn--fab.v-size--small {
  height: 35px !important;
  width: 35px !important;
}
.mhf-btn{
  background: -webkit-linear-gradient(top, rgb(28, 109, 249) 0%,rgb(22, 29, 165) 100%) !important;
}
</style>
<script>

import MetaInfoMixin from "@mixins/MetaInfoMixin";
import DatatableMixin from "@mixins/DatatableMixin";

export default {
  name: 'AddNoteDialog',
  helperApiName: 'carNote',
  mixins: [MetaInfoMixin, DatatableMixin],
  data() {
    const apiMethods = this.$api.methods[this.$options.helperApiName];
    return {
      apiMethods,
      formDialogLoading: false,
      formErrors: {},
      selectedItem: {},
      dialog: false,
    }
  },
  props: {
    show: {
      type: Boolean,
      required: !0
    },
    carid: {
      type: Number,
      required: !0
    },
    car_status: {
      type: Number,
      required: !0
    },
    exit_request: {
      type: [Boolean,Number,],
      default: false,
    },
  },
  computed: {
    showData: {
      set(n) {
        this.$emit('update:show', n);
      },
      get() {
        return this.show
      },
    },
  },
  methods: {
    note_types(){
      let notetypes=[];
      notetypes.push({text: this.parseAttribute('normal_note'),value:2});
      if(this.HasPermission('car_transindex')) {
        if (this.car_status === 3) {
          notetypes.push({text: this.parseAttribute('refuse_trans'), value: 1});
        }
      }
      if(this.car_status === 2||this.car_status === 8){
        notetypes.push({text: this.parseAttribute('re_trans'),value:4});
      }
      if(this.car_status === 4&&!this.request_exit){
        notetypes.push({text: this.parseAttribute('request_exit'),value:9});
      }
      if(this.car_status === 4&&this.request_exit){
        notetypes.push({text: this.parseAttribute('cancel_request_exit'),value:14});
      }
      if(this.HasPermission('car_delete')){
        if(this.car_status === 10){
          notetypes.push({text: this.parseAttribute('refuse_sale'),value:5});
          notetypes.push({text: this.parseAttribute('done_sale'),value:7});
          notetypes.push({text: this.parseAttribute('done_drop'),value:8});
        }
      }
      if(this.car_status === 11){
        notetypes.push({text: this.parseAttribute('re_sale'),value:6});
      }
      if(this.car_status < 4){
        notetypes.push({text: this.parseAttribute('request_cancel'),value:13});
      }
      if(this.HasPermission('car_delete')) {
        if (this.car_status === 13) {
          notetypes.push({text: this.parseAttribute('canceled'), value: 12});
        }
      }
      return notetypes;
    },
    submitNoteForm() {
      if (this.formDialogLoading) return;
      this.formDialogLoading = !0;
      this.selectedItem.car_id = this.carid;
      this.selectedItem.new_status = this.selectedItem.note_type;
      this.$axios.post(`Panel/AddNote`, this.selectedItem)
        .then(data => {
          if (data.data && data.data.success) {
          this.alertSuccess(data.message);
          this.showData = false;
        }
      })
        .catch(({response}) => {
          this.resetForm();
          const {data} = response || {};
          data && data.message && this.alertError(data.message);
          this.setErrors(data.errors || {});
        })
        .finally(() => this.formDialogLoading = !1);
    },
  },
}
</script>

