export default {
      name: 'name',
    namear: 'namear',
    role: 'role',
    login_id: 'login_id',
    password: 'password',
    client_id: 'العميل',
    transporter_id: 'الناقل',
    warehouse_id: 'المستودع',
    remember_token: 'remember_token',
    email_verified_at: 'email_verified_at',
    email: 'email',
    language: 'language',
    status: 'status',
    mobile: 'mobile',
    last_update: 'آخر تحديث',
};
