

import {APP_URL, defaultLocale, LOCALE_STORAGE_KEY} from '@/app/config'
import {localize} from '@plugins/vee-validate'
import {locales} from '@plugins/vue-i18n/register'
import {camelCase, isString} from 'lodash'
import {mapActions, mapGetters, mapMutations} from 'vuex'

const defaultAxiosCountDown = 310
const selectedSubItem= null;
export default {
  methods: {
    IsAppLocale(locale) {
      return this.AppLocales.find(l => l.code === locale)
    },
    SetAppLocale(locale) {
      this.AppLocale = locale
      this.$nextTick(() => window.location.reload())
    },
    IniAppLocale() {
      this.AppLocale = localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLocale
    },
    getPageTitle() {
      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const e = (...a) => this.$te(...a)
      const _ = this.$_
      let str = ''

      let {name, pageName} = this.$options || {}
      let routePath = this.$route.path.split('/').pop();
      let routeName = this.$route.name.split('/').pop();
      // console.log(this.$t(pageName));
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      routePath = camelCase(routePath)
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      routeName = camelCase(routeName)
      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      let $routes
      let $routes2

      $routes = `$routes.${routeName}`
      $routes2 = `$routes.${routeName}`
      if(routeName && e($routes) && isString((str = t($routes)))) {
        return str
      }
      if(routeName && e($routes2) && isString((str = t($routes2)))) {
        return str
      }
      let pluralize = _.pluralize(_.upperFirst(routeName.split('Index').join('').split('index').join('')))
      pluralize = pluralize.split('Client').join('').split('client').join('');
      // let pluralize2 = _.pluralize(_.upperFirst(routeName.split('Client').join('').split('client').join('')))
      $routes = `choice.${pluralize}`
      // console.log(tc($routes, 2));

      if($routes && e($routes) && isString((str = tc($routes, 2)))) {
        return str
      }

      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      name = camelCase(name)
      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      if(e(pageName) && isString((str = t(pageName)))) {
        return str
      }

      return ''
    },
    current_user_data() {
      return JSON.parse(localStorage.getItem('current_user'));
    },
    getPageName() {
      let returnOBJ = {};
      let routeName = this.$route.name.split('/').pop()
      // console.log(camelCase(routeName.split('Index').join('').split('index').join('')));
      returnOBJ.name = camelCase(routeName.split('Index').join('').split('index').join(''))
      returnOBJ.name = camelCase(routeName.split('Client').join('').split('client').join(''))
      returnOBJ.nameStudly = this.$_.upperFirst(returnOBJ.name)
      return returnOBJ;
    },
    parseAttribute(string, ...args) {
      if(!string) return string

      const _ = this.$_, t = (...a) => this.$t(...a), te = (...a) => this.$te(...a)

      let key = (this.$helpers.isOnlyObject(string) ? (string.text || '') : string).toString()

      if(te(key) && isString(t(key)))
        return t(key, ...args)

      if(te(`attributes.${key}`) && isString(t(`attributes.${key}`)))
        return t(`attributes.${key}`, ...args)

      return string
    },
    HasEditPermission() {
      // console.log(this.getPageName().name + '_edit');
      return this.HasPermission(this.getPageName().name + '_edit');
    },
    HasDeletePermission() {
      return this.HasPermission(this.getPageName().name+'_delete');
    },
    HasPermission(string) {
      if(!string) return false

      let allowedperms = JSON.parse(localStorage.getItem('perms'));

      if(allowedperms){
        if(allowedperms[string]){
          return true;
        }
      }
      return false
    },
    HasWarehouses() {
      let allowedperms = JSON.parse(localStorage.getItem('warehouses'));

      if(allowedperms){
        if(allowedperms.length>0){
          return true;
        }
      }
      return false
    },
    getAxiosName() {
      return this.$options.name
    },
    getAxiosItems() {
      return this.getIniAxios(this.getAxiosName())
    },
    carstutasnames(va) {
      if(va){
        return this.$t('attributes.carstutas'+va)
      }

      return '-';
    },
    CarStatusSelect() {
      return [
        {text: this.parseAttribute('carstutas0'), value: 0},
        {text: this.parseAttribute('carstutas1'), value: 1},
        {text: this.parseAttribute('carstutas2'), value: 2},
        {text: this.parseAttribute('carstutas3'), value: 3},
        {text: this.parseAttribute('carstutas4'), value: 4},
        {text: this.parseAttribute('carstutas5'), value: 5},
        {text: this.parseAttribute('carstutas6'), value: 6},
        {text: this.parseAttribute('carstutas7'), value: 7},
        {text: this.parseAttribute('carstutas8'), value: 8},
        {text: this.parseAttribute('carstutas9'), value: 9},
        {text: this.parseAttribute('carstutas10'), value: 10},
        {text: this.parseAttribute('carstutas11'), value: 11},
        {text: this.parseAttribute('carstutas13'), value: 13},
        {text: this.parseAttribute('carstutas12'), value: 12},
      ]
    },
    CarRequestStatusSelect() {
      return [
        {text: this.parseAttribute('carrequeststutas0'), value: 0},
        {text: this.parseAttribute('carrequeststutas1'), value: 1},
        {text: this.parseAttribute('carrequeststutas2'), value: 2},
        {text: this.parseAttribute('carrequeststutas3'), value: 3},
      ]
    },
    RequestStatusSelect() {
      return [
        {text: this.parseAttribute('requeststutas0'), value: 0},
        {text: this.parseAttribute('requeststutas1'), value: 1},
        {text: this.parseAttribute('requeststutas2'), value: 2},
        {text: this.parseAttribute('requeststutas3'), value: 3},
        {text: this.parseAttribute('requeststutas4'), value: 4},
        {text: this.parseAttribute('requeststutas5'), value: 5},
      ]
    },
    carstutasobject() {
      return [
        {
          text: this.$t('attributes.carstutas0'),
          value: 0
        },
        {
          text: this.$t('attributes.carstutas1'),
          value: 1
        },
        {
          text: this.$t('attributes.carstutas2'),
          value: 2
        },
        {
          text: this.$t('attributes.carstutas3'),
          value: 3
        },
        {
          text: this.$t('attributes.carstutas4'),
          value: 4
        },
        {
          text: this.$t('attributes.carstutas5'),
          value: 5
        },
        {
          text: this.$t('attributes.carstutas6'),
          value: 6
        },
        {
          text: this.$t('attributes.carstutas7'),
          value: 7
        },
        {
          text: this.$t('attributes.carstutas8'),
          value: 8
        }
      ]
    },
    client_belongs() {
      return [
        {
          text: this.$t('attributes.client_belong1'),
          value: 1
        },
        {
          text: this.$t('attributes.client_belong2'),
          value: 2
        }
      ]
    },
    menutypes() {
      return [
        {
          text: this.$t('attributes.menu_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.menu_type2'),
          value: 2
        }
      ]
    },
    itemtypes() {
      return [
        {
          text: this.$t('attributes.item_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.item_type2'),
          value: 2
        },
        {
          text: this.$t('attributes.item_type3'),
          value: 3
        },
        {
          text: this.$t('attributes.item_type4'),
          value: 4
        },
        {
          text: this.$t('attributes.item_type5'),
          value: 5
        },
      ]
    },
    GlobalStatus() {
      return [
        {
          text: this.parseAttribute('enabled'),
          value: true
        },
        {
          text: this.parseAttribute('disabled'),
          value: false
        },
      ]
    },
    GlobalStatus2() {
      return [
        {
          text: this.parseAttribute('yes'),
          value: 1
        },
        {
          text: this.parseAttribute('no'),
          value: 0
        },
      ]
    },
    servicerequesttypes() {
      return [
        {
          text: this.$t('attributes.service_request_type0'),
          value: 0
        },
        {
          text: this.$t('attributes.service_request_type1'),
          value: 1
        }
      ]
    },
    setIniAxios(name, v) {
      this.$root.iniAxios[name] = v
    },
    getIniAxios(name) {
      return null
    },
    increaseAxios(countdown = defaultAxiosCountDown) {
      countdown = parseInt(countdown) || defaultAxiosCountDown
      this.$root.AxiosCountdown += countdown
      // console.log(this.$root.AxiosCountdown);
      return this.getAxiosCountdown()
    },
    setAxiosCountdown(countdown = defaultAxiosCountDown) {
      this.$root.AxiosCountdown = countdown
    },
    getAxiosCountdown() {
      return this.$root.AxiosCountdown
    },
    queueAxios(func, countdown = defaultAxiosCountDown) {
      // console.log(countdown);
      setTimeout(() => setTimeout(() => this.$nextTick(func), this.increaseAxios(countdown)), 60)
    },
    YesNoSelect() {
      return [
        {text: this.parseAttribute('no'), value: false},
        {text: this.parseAttribute('yes'), value: true},
      ]
    },
  },
  computed: {
    APP_DEBUG: () => process.env.NODE_ENV === 'development',
    DefaultAppLocale: () => defaultLocale,
    AppRtl: {
      set(v) {
        this.$root.$vuetify.rtl = v
      },
      get() {
        const {rtl} = this.$root.$vuetify || {}
        return rtl !== undefined ? rtl : true
      }
    },
    Permissions: {
      set(v) {

      },
      get() {
        return JSON.parse(localStorage.getItem('perms'));
      }
    },
    Warehouses: {
      set(v) {

      },
      get() {
        return JSON.parse(localStorage.getItem('warehouses'));
      }
    },
    AppDirection: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'rtl'
      },
      get() {
        return this.AppRtl ? 'rtl' : 'ltr'
      }
    },
    AppAlign: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'right'
      },
      get() {
        return this.AppRtl ? 'right' : 'left'
      }
    },
    AppLocales() {
      return locales.map(code => ({title: this.$t(code), code}))
    },
    AppLocale: {
      set(locale = defaultLocale) {
        locale = ('' + locale).toString().toLocaleLowerCase()

        !this.IsAppLocale(locale) && (locale = defaultLocale)

        localStorage.setItem(LOCALE_STORAGE_KEY, locale)
        document.documentElement.setAttribute('lang', locale)
        document.documentElement.setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

        // Vuetify
        this.$root.$i18n.locale = locale
        this.$root.$vuetify.lang.current = locale
        this.$root.$vuetify.rtl = locale === 'ar'

        // axios
        this.$axios.defaults.headers.common['Locale'] = locale

        // Vuex
        const validations = require(`@locales/${locale}/validations.js`).default
        localize(locale, validations.default || validations)

        // moment js
        this.$moment.locale(locale)
      },
      get() {
        return this.$root.$vuetify.lang.current
      }
    },
    themeDark: {
      get() {
        return Boolean(mapGetters('auth', ['themeDark']).themeDark.call(this))
      },
      set(value) {
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeLight: {
      get() {
        // console.log(this.themeDark)
        return !Boolean(this.themeDark)
      },
      set(value) {
        value = !value
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeColor() {
      return this.themeDark ? 'dark' : 'light'
    },
    AppIsMobile() {
      return this.$root.$vuetify.breakpoint.smAndDown || false
    },
    AppOpositeAlign: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'left'
      },
      get() {
        return this.AppRtl ? 'left' : 'right'
      }
    },
    authUser: {
      set(user) {
        mapMutations('auth', ['setUser']).setUser.call(this, user)
      },
      get() {
        return mapGetters('auth', ['getUser']).getUser.call(this)
      }
    },
    apiService() {
      return this.$api.methods
    },
    MealsPerRow: {
      set(v) {
        this.MealsPerRow = v? v: 5;
      },
      get() {
        return this.MealsPerRow;
      }
    },
    POSSettings: function () { return window.defaultSettings; },
    UserSettings: function () { return window.userSettings; },
    UserPOSPerms: function () { return window.userPOSPerms; },
  }
}
